import React from 'react';
import {navigate} from "gatsby";
import './QuizSuccess.scss';

export default function QuizPage() {
    return (
        <div className="ContainerQuizSuccess">
            <div className="HeadingQuizSuccess"><div>Wie fit ist ihr Unternehmen?</div></div>
            <div className="SuccessHeading">Das ausgefüllte Quiz wurde erfolgreich übermittelt.</div>
            <div className="SuccessText">Sie bekommen Ihre persönliche Analyse an die von Ihnen angegebene E-Mail Adresse übersendet.</div>
            <button className="BackButtonQuizSuccess" onClick={() => navigate("/")}>Zurück zur Startseite</button>
            <div className="Disclaimer">            
                <div className="DisclaimerHeading">Disclaimer</div>
                <div className="DisclaimerText">
                    HSE365AT besitzt ein nach EN ISO 13485 zertifiziertes QM-System und hat Erfahrung in
                    diversen Entwicklungsprojekten von Software als Medizinprodukt. HSE365AT ist selbst
                    nicht berechtigt, eine Zertifizierung anzubieten oder durchzuführen. Insofern
                    handelt es sich bei diesem Service um reine Informations- und Beratungsleistung der
                    HSE365AT. HSE365AT haftet weder für materielle noch für immaterielle Schäden, die
                    sich für den Kunden als Folge dieses Services ergeben könnten. Entscheidungen, die
                    auf Basis dieses Services getroffen werden, liegen ausschließlich in der
                    Verantwortung des Kunden. Insbesondere haftet HSE365AT nicht für etwaige gesetzliche
                    oder regulatorische Versäumnisse des Kunden, die vom Kunden vor Inanspruchnahme
                    dieses Services oder durch Missachtung von Vorgaben der HSE365AT zustande kommen
                    könnten oder zustande gekommen sind. HSE365AT weist ausdrücklich darauf hin, dass
                    die Herstellung und Inverkehrbringung von In Vitro-Diagnostika und Medizinprodukten
                    dem nationalen Medizinproduktegesetz und der VERORDNUNG (EU) 2017/746 DES
                    EUROPÄISCHEN PARLAMENTS UND DES RATES (IVDR) und der VERORDNUNG (EU) 2017/745 DES
                    EUROPÄISCHEN PARLAMENTS UND DES RATES (MDR) unterliegen.
                </div>
            </div>
        </div>
    );
}